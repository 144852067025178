import React from "react";
import {Router} from "@reach/router"
import ArticleDetail from "../../components/investment-ideas/ArticleDetail";

const UniversityIndex = () => {

    return (
        <Router basepath="/investment-ideas">
            <ArticleDetail path="/:slug"/>
        </Router>
    );
};

export default UniversityIndex;